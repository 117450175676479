<template>
  <div></div>
</template>
<script>
import {DataService} from "@/core/services/DataService";

export default {
  name: "Define.vue",
  created() {
    const userData = DataService.getAuthUserData()

    if (!userData) {
      this.$router.push('/sign-in')
      return
    }
    if (userData.is_super_admin) {
      this.$router.push('/super-admin/dashboard')
    } else {
      if (userData.is_bank_user) {
        this.$router.push('/bank/dashboard')
      } else {
        this.$router.push('/business/dashboard')
      }
    }
  }
}
</script>
